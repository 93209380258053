const mapPagingObjectToQueryString = (paging, queryString = '') => {
	if (paging) {
		if (queryString.length > 0) queryString = queryString + '&';
		queryString = `${Object.keys(paging)
			.map((key) => key + '=' + paging[key])
			.join('&')}`;
	}

	return queryString;
};

const mapFilterArrayToQueryString = (filter, queryString = '') => {
	if (filter && filter.length > 0) {
		if (queryString.length > 0) queryString = queryString + '&';
		queryString =
			queryString + filter.map((f) => f.columnId + '=' + f.filter).join('&');
	}

	return queryString;
};

const mapSortArrayToQueryString = (sort, queryString = '') => {
	if (sort && sort.length > 0) {
		if (queryString.length > 0) queryString = queryString + '&';
		queryString =
			queryString +
			sort.map((f) => 'orderBy=' + f.id).join('&') +
			'&' +
			sort.map((f) => 'orderDesc=' + f.desc).join('&');
	}

	return queryString;
};

function getParameterByName(name, url) {
	if (!url) url = window.location.href.toLowerCase();
	name = name.toLowerCase().replace(/[\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getCurrentUrl() {
	return window.location.href.toLowerCase();
}

export default {
	mapPagingObjectToQueryString,
	mapFilterArrayToQueryString,
	mapSortArrayToQueryString,
	getParameterByName,
	getCurrentUrl,
};
