const isValidUrl = (urlString) => {
	try {
		return Boolean(new URL(urlString));
	} catch (e) {
		return false;
	}
};

function getImageUrl(tag, mediaFiles, readToken, checkIsUrl = false) {
	if (checkIsUrl && isValidUrl(tag)) {
		return tag;
	}
	if (!mediaFiles) {
		return;
	}
	var mediaFile = mediaFiles.find((mf) => mf.tag === tag);
	const readTokenString = readToken ? `?${readToken}` : '';
	return (
		mediaFile && mediaFile.blobUrl && `${mediaFile.blobUrl}${readTokenString}`
	);
}

export const experienceMapperHelper = {
	getImageUrl,
};
