class GeoLocateIp {
	providers = [
		{
			url: 'https://get.geojs.io/v1/ip/geo.json',
			countryCodeProperty: 'country_code',
			countryNameProperty: 'country',
		},
		{
			url: 'https://ipapi.co/json',
			countryCodeProperty: 'country_code',
			countryNameProperty: 'country_name',
		},
		{
			url: 'https://api.hostip.info/get_json.php',
			countryCodeProperty: 'country_code',
			countryNameProperty: 'country_name',
		},
	];

	async getCountry() {
		let country;
		let providerIndex = 0;
		do {
			const response = await fetch(this.providers[providerIndex].url);
			if (response.ok) {
				const result = await response.json();
				country = {
					countryCode:
						result[this.providers[providerIndex].countryCodeProperty],
					countryName:
						result[this.providers[providerIndex].countryNameProperty],
				};
			}
			providerIndex++;
		} while (!country && providerIndex < this.providers.length);

		return country;
	}
}

export const geolocateIp = new GeoLocateIp();
