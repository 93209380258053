import { replacePageContentHelper } from '../../../services';

export function useReplaceableRatings(articles = [], mediaFiles = []) {
	const replaceRatings = (htmlData, ratingsData) => {
		if (!htmlData || !ratingsData) {
			return;
		}

		const votedRatings = ratingsData.filter(
			(r) =>
				r.value !== null &&
				r.value !== undefined &&
				r.value.toString().length > 0
		);

		const votedFirstRatingOption = articles
			.flatMap((a) =>
				(a.ratingItems ?? [])?.flatMap((ratingItem) => {
					return (ratingItem.options || []).map((o) => ({
						...o,
						order: ratingItem?.order,
						ratingItemId: ratingItem?.id,
					}));
				})
			)
			?.filter(
				(r) =>
					votedRatings.findIndex(
						(vr) =>
							r.ratingItemId === vr.ratingItemId && r.reference === vr.value
					) >= 0
			)
			?.sort((r) => r.order)[0];

		if (!votedFirstRatingOption) {
			return;
		}

		return replacePageContentHelper.replaceFields(
			htmlData,
			votedFirstRatingOption,
			mediaFiles
		);
	};

	return {
		replaceRatings,
	};
}
