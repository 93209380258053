import React from 'react';
import { Row, Col } from 'reactstrap';
import { group, label } from '../product-info.styles';
import ProductInfoHeaderButton from './product-info-header-button';
import ProductInfoHeaderBackButton from './product-info-header-back-button';
import {
	titleRow,
	headerBackButtonColumn,
	headerProductNameColumn,
	headerIconColumn,
} from './product-info-header.styles';

const ProductInfoHeader = (props) => {
	const {
		productName,
		setViewByName,
		showBackButton,
		fontColor,
		topIcons,
		previousViewReference,
		showTitle,
	} = props;

	return (
		<Row style={titleRow}>
			<Col xs={2} style={headerBackButtonColumn}>
				{showBackButton && (
					<ProductInfoHeaderBackButton
						{...props}
						alt='Back'
						onClick={() => {
							setViewByName('ProductInfo', previousViewReference);
						}}
					/>
				)}
			</Col>

			<Col xs={6} style={headerProductNameColumn}>
				{showTitle && (
					<div style={group}>
						<span style={{ ...label, fontSize: 30, color: fontColor }}>
							{productName || ''}
						</span>
					</div>
				)}
			</Col>
			<Col xs={4} style={headerIconColumn}>
				{topIcons &&
					topIcons.map((icon, key) => (
						<ProductInfoHeaderButton
							{...props}
							key={key}
							image={icon.imageUrl}
							alt='Pairing'
							onClick={
								icon.targetLink
									? () => window.open(icon.targetLink, '_blank')
									: () => setViewByName('ProductImageView', icon.id)
							}
						/>
					))}
			</Col>
		</Row>
	);
};

export default ProductInfoHeader;
