import apiHelper from './queryStringHelper';

const composeQueryString = (paging, filterBy, sortBy) => {
	let queryString = '';

	queryString = apiHelper.mapPagingObjectToQueryString(paging, queryString);
	queryString = apiHelper.mapFilterArrayToQueryString(filterBy, queryString);
	queryString = apiHelper.mapSortArrayToQueryString(sortBy, queryString);

	if (queryString.length > 0) queryString = `?${queryString}`;

	return queryString;
};

const composeOptionsForGetMethod = (accessToken) => {
	const options = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			Accept: 'application/json',
		},
		method: 'GET',
	};

	return options;
};

const composeOptionsForCreateMethod = (accessToken, data) => {
	const options = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(data),
	};

	return options;
};

const composeOptionsForUpdateMethod = (accessToken, data) => {
	const options = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		body: JSON.stringify(data),
	};

	return options;
};

const composeOptionsForGetWriteTokenMethod = (accessToken) => {
	const options = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
		method: 'POST',
	};

	return options;
};

const composeOptionsForDeleteMethod = (accessToken, data) => {
	const options = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		method: 'DELETE',
		body: JSON.stringify(data),
	};

	return options;
};

const composeOptionsForAnonymousPost = (data) => {
	const options = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(data),
	};

	return options;
};

const composeOptionsForAnonymousGet = () => {
	const options = {
		headers: {
			Accept: 'application/json',
		},
		method: 'GET',
	};

	return options;
};

const composeOptionsForAnonymousPut = (data) => {
	const options = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		body: JSON.stringify(data),
	};

	return options;
};

export default {
	composeQueryString,
	composeOptionsForGetMethod,
	composeOptionsForCreateMethod,
	composeOptionsForUpdateMethod,
	composeOptionsForGetWriteTokenMethod,
	composeOptionsForDeleteMethod,
	composeOptionsForAnonymousPost,
	composeOptionsForAnonymousGet,
	composeOptionsForAnonymousPut,
};
