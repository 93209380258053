import { width } from 'assets/common.styles';

const socialContainer = {
	marginTop: 20,
};

const socialIcon = {
	marginBottom: 20,
	width: width / 4 - 2,
	display: 'inline-block',
};

export { socialContainer, socialIcon };
