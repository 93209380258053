import { secondaryColor } from '../product-info.styles';

const titleRow = {
	display: 'flex',
	justifyContent: 'center',
	paddingTop: 29,
	paddingBottom: 29,
	margin: '0px 35px 60px 35px',
};

const headerColumnCommon = {
	alignSelf: 'center',
};

const headerBackButtonColumn = {
	...headerColumnCommon,
	flex: 2,
	fontSize: 30,
	textAlign: 'left',
};

const headerProductNameColumn = {
	flex: 6,
	letterSpacing: 2,
};

const headerLogo = {
	width: 64,
};

export {
	titleRow,
	headerBackButtonColumn,
	headerProductNameColumn,
	headerLogo,
	secondaryColor,
};
