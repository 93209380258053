import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { titleRow } from './product-info-horizontal.styles';
import { content, productImage, rows } from '../product-info.styles';
import { ProductFields } from '../product-fields';
import SocialButtons from '../social-buttons/social-buttons';
import { templateHelper } from 'services';
import ProductInfoHeader from '../product-info-header/product-info-header';
import ProductImageLink from '../product-image-link/product-image-link';

const ProductInfoHorizontal = (props) => {
	const {
		productName,
		productFields,
		template,
		mediaFiles,
		token,
		setViewByName,
		fontColor,
		topIcons,
		reference,
		showTitle,
	} = props;
	const [headerImageUrl, headerImageTargetLink] =
		templateHelper.getImageFromTemplate(template, mediaFiles, 'Header', token);
	const [dividerImageUrl, dividerImageTargetLink] =
		templateHelper.getImageFromTemplate(
			template,
			mediaFiles,
			'HorizontalDivider',
			token
		);

	return (
		<Container className='row' style={content}>
			<ProductInfoHeader
				productName={productName}
				showTitle={showTitle}
				setViewByName={setViewByName}
				showBackButton={false}
				fontColor={fontColor}
				topIcons={topIcons}
				previousViewReference={reference}
			/>
			<Row style={rows}>
				<Col xs='12'>
					{headerImageUrl && (
						<ProductImageLink
							imageUrl={headerImageUrl}
							imageTargetLink={headerImageTargetLink}
							title={productName || 'Product'}
							style={productImage}
						/>
					)}
				</Col>
			</Row>
			<Row style={titleRow}>
				<Col xs='12'>
					<ProductFields
						fields={productFields ? productFields.slice(0, 4) : []}
						fontColor={fontColor}
					/>
					{dividerImageUrl && (
						<ProductImageLink
							imageUrl={dividerImageUrl}
							imageTargetLink={dividerImageTargetLink}
							title={productName || 'Product'}
							style={productImage}
						/>
					)}
					<ProductFields
						fields={
							productFields ? productFields.slice(5, productFields.length) : []
						}
						fontColor={fontColor}
					/>
				</Col>
			</Row>
			<SocialButtons {...props} />
		</Container>
	);
};

export default ProductInfoHorizontal;
