import React from 'react';
import { Container } from 'reactstrap';
import { group, roundImageCigar, andorraValue } from '../product-info.styles';
import { ProductPriceCountry } from './product-price-country';
import { numberHelper } from 'services';
import { CigarShopAndorraFlag } from 'assets/img';
import { productJsonFieldNames } from '../productJsonFieldNames';

const localeCode = 'ca-AD';

export const ProductPrice = (props) => {
	const { product, fontColor, country } = props;

	return (
		<Container
			style={{
				marginLeft: 27,
				marginRight: 27,
				marginBottom: 35,
				borderRadius: 20,
				backgroundColor: '#2E2927',
				border: '1px solid transparent',
				boxShadow: '0px 4px 8px #000000',
			}}
		>
			<img src={CigarShopAndorraFlag} style={roundImageCigar} alt='Cigar' />

			<div style={{ ...group, marginLeft: 15, marginRight: 15 }}>
				<span
					style={{
						fontSize: 24,
						color: fontColor,
					}}
				>
					{product[productJsonFieldNames.MARCA]}
				</span>
				<br />
				<span
					style={{
						fontSize: 24,
						color: fontColor,
						fontWeight: 900,
					}}
				>
					{product[productJsonFieldNames.ARTICULO].replace(
						product[productJsonFieldNames.MARCA],
						''
					)}
				</span>
			</div>
			<div style={andorraValue}>
				{numberHelper.asCurrencyString(
					product[productJsonFieldNames.ANDORRA_PVPM],
					localeCode
				)}
			</div>
			{(!country || country === 'Spain') && (
				<ProductPriceCountry
					fontColor={fontColor}
					country='Spain'
					originalPrice={numberHelper.asCurrencyString(
						product[productJsonFieldNames.ESPANA_PVP],
						localeCode
					)}
					savingPrice={numberHelper.asCurrencyString(
						product[productJsonFieldNames.ESPANA_SAVING],
						localeCode
					)}
					savingPercent={numberHelper.asPercentString(
						product[productJsonFieldNames.ESPANA_SAVING_PERCENT]
					)}
				/>
			)}
			{(!country || country === 'France') && (
				<ProductPriceCountry
					fontColor={fontColor}
					country='France'
					originalPrice={numberHelper.asCurrencyString(
						product[productJsonFieldNames.FRANCIA_PVP],
						localeCode
					)}
					savingPrice={numberHelper.asCurrencyString(
						product[productJsonFieldNames.FRANCIA_SAVING],
						localeCode
					)}
					savingPercent={numberHelper.asPercentString(
						product[productJsonFieldNames.FRANCIA_SAVING_PERCENT],
						localeCode
					)}
				/>
			)}
		</Container>
	);
};
