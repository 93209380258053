const modal = ({ fontColor, backgroundColor = '#000000' }) => {
	const modalBackgroundColor =
		backgroundColor && backgroundColor.length > 7
			? backgroundColor
			: `${backgroundColor}f2`;
	return {
		position: 'fixed',
		zIndex: 1,
		left: 0,
		top: 0,
		height: '100%',
		overflow: 'auto',
		color: fontColor,
		backgroundColor: modalBackgroundColor,
		display: 'flex',
		flexDirection: 'column',
		padding: '0px 15px',
		letterSpacing: 2,
		textAlign: 'center',
	};
};

const modalHeader = {
	marginTop: 20,
};

const cookieInformationContainer = {
	textAlign: 'left',
};

const paragraph = {
	fontSize: 12,
};

const table = {
	fontSize: 12,
	borderCollapse: 'separate',
	borderSpacing: '0.5rem 1rem',
};

const row = {
	verticalAlign: 'top',
};

const closeButtonContainer = {
	marginTop: 15,
	marginBottom: 15,
};

const button = ({ fontColor }) => {
	return {
		padding: 10,
		background: 'transparent',
		letterSpacing: 2,
		color: fontColor,
		borderColor: fontColor,
	};
};

export {
	modal,
	modalHeader,
	cookieInformationContainer,
	paragraph,
	table,
	row,
	button,
	closeButtonContainer,
};
