export const sanitizedHtmlConfig = {
	allowedTags: [
		'a',
		'abbr',
		'address',
		'article',
		'aside',
		'b',
		'bdi',
		'bdo',
		'blockquote',
		'body',
		'br',
		'button',
		'caption',
		'cite',
		'code',
		'col',
		'colgroup',
		'data',
		'dd',
		'dfn',
		'div',
		'dl',
		'dt',
		'em',
		'figcaption',
		'figure',
		'footer',
		'h1',
		'h2',
		'h3',
		'h4',
		'h5',
		'h6',
		'head',
		'header',
		'hgroup',
		'hr',
		'html',
		'i',
		'iframe',
		'img',
		'input',
		'kbd',
		'li',
		'main',
		'mark',
		'nav',
		'ol',
		'option',
		'p',
		'pre',
		'q',
		'quote',
		'rb',
		'rp',
		'rt',
		'rtc',
		'ruby',
		's',
		'samp',
		'section',
		'select',
		'small',
		'span',
		'strike',
		'strong',
		'style',
		'sub',
		'sup',
		'table',
		'tbody',
		'td',
		'tfoot',
		'th',
		'thead',
		'time',
		'tr',
		'u',
		'ul',
		'var',
		'video',
		'wbr',
		'form',
		'fieldset',
		'label',
	],
	allowedAttributes: {
		a: ['*'],
		blockquote: ['*'],
		button: ['*'],
		div: ['*'],
		iframe: ['*'],
		img: ['*'],
		input: ['*'],
		main: ['*'],
		p: ['*'],
		span: ['*'],
		style: ['*'],
		header: ['*'],
		form: ['*'],
		select: ['*'],
		option: ['*'],
		h3: ['*'],
		fieldset: ['*'],
		label: ['*'],
	},
	allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel', 'data'],
	allowProtocolRelative: true,
	enforceHtmlBoundary: false,
	disallowedTagsMode: 'discard',
};
