import React from 'react';
import SanitizedHTML from 'react-sanitized-html';
import { sanitizedHtmlConfig } from './sanitizedHtmlConfig';

const ProductCustomPageContent = (props) => {
	const { content, token } = props;

	return (
		<SanitizedHTML
			html={content?.replaceAll('#TOKEN#', `?${token}`)}
			{...sanitizedHtmlConfig}
			allowedAttributes={null}
		/>
	);
};

export default ProductCustomPageContent;
