import React from 'react';
import { background, img, text } from './empty-page.styles';

const EmptyPage = ({
	backgroundColor,
	fontColor,
	fontFamily,
	message,
	image,
	backgroundImage,
}) => {
	return (
		<div
			style={{
				...background,
				backgroundColor: backgroundColor,
				color: fontColor,
				backgroundImage: backgroundImage
					? 'url(' + backgroundImage + ')'
					: 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			}}
		>
			{image && (
				<img
					src={image}
					alt='logo'
					style={{
						...img,
					}}
				/>
			)}

			<p
				style={{
					...text,
					color: fontColor,
					fontFamily: fontFamily,
				}}
			>
				{message}
			</p>
		</div>
	);
};

export default EmptyPage;
