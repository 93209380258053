import React from 'react';
import YouTube from 'react-youtube';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';

const opts = {
	height: window.screen.height + 100,
	width: window.screen.width,
	playerVars: {
		// https://developers.google.com/youtube/player_parameters
		autoplay: 0,
	},
};

const onReady = (event) => {
	// access to player in all event handlers via event.target
	event.target.playVideo();
};

const PromotionalVideo = (props) => {
	const { t } = useTranslation(NAME_SPACE.COMMON);
	const { fontColor, youtubeUrl, backgroundColor } = props;

	const onStateChange = (state) => {
		console.log(state);
		if (state.data === 0) props.setNextView();
	};

	const getYoutubeId = (url) => {
		url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
		return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
	};

	return (
		<div>
			<div
				style={{
					position: 'fixed',
					left: 0,
					top: 0,
					marginTop: -100,
					backgroundColor: backgroundColor,
				}}
			>
				<YouTube
					videoId={getYoutubeId(youtubeUrl)}
					opts={opts}
					onReady={onReady}
					onStateChange={onStateChange}
				/>
			</div>
			<br />
			<Button
				style={{
					backgroundColor: 'transparent',
					fontSize: 22,
					color: fontColor,
					borderColor: fontColor,
					borderRight: 'none',
					borderLeft: 'none',
					position: 'fixed',
					width: window.screen.width - 60,
					left: 0,
					margin: 30,
					bottom: 50,
					letterSpacing: 3,
					borderRadius: 0,
				}}
				onClick={() => {
					props.setNextView();
				}}
			>
				{t('actions.skip')}
			</Button>
		</div>
	);
};

export default PromotionalVideo;
