import React, { useMemo } from 'react';
import { Fail, Success } from 'assets/img';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import CookiePolicyLink from '../cookie-policy/cookie-policy-link';
import { mainDiv } from '../product-info/product-info.styles';
import { TamperResult } from './tamperResult';

const AuthResultDefault = (props) => {
	const { t } = useTranslation(NAME_SPACE.AUTH_RESULT);
	const {
		fontColor,
		fontFamily,
		backgroundColor,
		backgroundImageUrl,
		authenticationResult,
		continueButtonText,
		authenticationData,
		usesTagTamper,
		tamperStatuses,
		serialNumber,
	} = props;

	const authStatus = useMemo(() => {
		let data;
		if (authenticationResult) {
			data = {
				imageUrl: authenticationData?.successful?.imageUrl || Success,
				text: authenticationData?.successful?.text || '',
			};
		} else {
			data = {
				imageUrl: authenticationData?.unsuccessful?.imageUrl || Fail,
				text: authenticationData?.unsuccessful?.text || t('scanNotFound'),
			};
		}
		return data;
	}, [authenticationResult, authenticationData]);

	return (
		<div
			id='maindiv'
			style={{
				...mainDiv('100vh', backgroundImageUrl, backgroundColor, fontColor),
				letterSpacing: 2,
				marginTop: 0,
			}}
		>
			<div
				style={{
					width: 200,
					height: 270,
					position: 'fixed',
					top: tamperStatuses ? '45%' : '50%',
					left: '50%',
					marginTop: -100,
					marginLeft: -100,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<img width='120' height='120' src={authStatus?.imageUrl} alt='logo' />

				<p
					style={{
						color: fontColor,
						fontFamily: fontFamily,
						paddingTop: 5,
						fontSize: 20,
					}}
				>
					{authStatus?.text && authStatus?.text}
				</p>
				{authenticationResult && usesTagTamper && tamperStatuses && (
					<TamperResult
						fontColor={fontColor}
						fontFamily={fontFamily}
						tamperStatuses={tamperStatuses}
					/>
				)}
				{authenticationResult && serialNumber && (
					<p
						style={{
							color: fontColor,
							fontFamily: fontFamily,
							paddingTop: 18,
							fontSize: 16,
							wordBreak: 'break-word',
							fontWeight: 'bold',
						}}
					>
						{serialNumber}
					</p>
				)}
			</div>
			<Button
				style={{
					backgroundColor: 'transparent',
					fontSize: 22,
					color: fontColor,
					borderColor: fontColor,
					borderRight: 'none',
					borderLeft: 'none',
					position: 'fixed',
					width: window.screen.width - 60,
					left: 0,
					margin: 30,
					bottom: 50,
					borderRadius: 0,
				}}
				onClick={() => {
					authenticationResult ? props.setNextView() : window.close();
				}}
			>
				{authenticationResult ? continueButtonText ?? t('continue') : t('done')}
			</Button>
			<div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
				<CookiePolicyLink
					modalStyles={{
						fontColor: fontColor,
						backgroundColor: backgroundColor,
					}}
				/>
			</div>
		</div>
	);
};

export default AuthResultDefault;
