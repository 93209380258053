const getImageFromTemplate = (
	template,
	mediaFiles,
	referenceCode,
	readToken
) => {
	if (template && template.images && mediaFiles && readToken) {
		let imageTemplateIdx = template.images.findIndex(
			(ti) => ti.referenceCode === referenceCode
		);
		if (imageTemplateIdx > -1) {
			let imageTemplateId = template.images[imageTemplateIdx].id;
			let mediaFileIdx = mediaFiles.findIndex(
				(mf) => mf.imageTemplateId === imageTemplateId
			);
			if (mediaFileIdx > -1)
				return [
					`${mediaFiles[mediaFileIdx].blobUrl}?${readToken}`,
					mediaFiles[mediaFileIdx].targetLink,
				];
		}
	}
};

const compareTags = (a, b) => {
	if (a.tag < b.tag) {
		return -1;
	}
	if (a.tag > b.tag) {
		return 1;
	}
	return 0;
};

const getUnattachedImages = (
	pages,
	mediaFiles,
	readToken,
	icons,
	authenticationData,
	tamperStatuses,
	defaultExperienceData
) => {
	if (pages && mediaFiles && readToken) {
		var pageBackgroundTags = pages
			.filter((p) => p.backgroundImageTag)
			.map((p) => p.backgroundImageTag);

		var iconTags = icons
			.filter((icon) => icon.iconImageTag)
			.map((icon) => icon.iconImageTag);

		var iconTargetTags = icons
			.filter((icon) => icon.targetImageTag)
			.map((icon) => icon.targetImageTag);

		var imageLinks = mediaFiles
			.filter(
				(mf) =>
					mf.blobUrl &&
					!mf.imageTemplateId &&
					mf.tag !== authenticationData?.successful?.tag &&
					mf.tag !== authenticationData?.unsuccessful?.tag &&
					!pageBackgroundTags.some((pageTag) => mf.tag === pageTag) &&
					!iconTags.some((pageTag) => mf.tag === pageTag) &&
					!iconTargetTags.some((pageTag) => mf.tag === pageTag) &&
					!tamperStatuses?.some(
						(tamperStatus) => mf.tag === tamperStatus.tag
					) &&
					mf.tag !== defaultExperienceData?.experienceDefaultBackgroundImage &&
					mf.tag !== defaultExperienceData?.experienceDefaultImage
			)
			.map((mf) => [`${mf.blobUrl}?${readToken}`, mf.targetLink]);

		return imageLinks.sort(compareTags);
	}
	return [];
};

export default { getImageFromTemplate, getUnattachedImages };
