import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { resources } from './assets/locales';

export const NAME_SPACE = {
	COMMON: 'common',
	ANDORRA_CIGAR_SHOP: 'andorraCigarShop',
	LEGAL_TERMS: 'legalTerms',
	AUTH_RESULT: 'authResult',
	COOKIE_POLICY: 'cookiePolicy',
	SCAN_RATINGS: 'scanRatings',
};

export const LANGUAGE = {
	ENGLISH: 'en',
	SPANISH: 'es',
	CATALAN: 'ca',
	FRENCH: 'fr',
};

export const getLanguage = () =>
	i18n.language || window.localStorage.i18nextLng;

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: [
			LANGUAGE.ENGLISH,
			LANGUAGE.FRENCH,
			LANGUAGE.SPANISH,
			LANGUAGE.CATALAN,
		],
		ns: [
			NAME_SPACE.COMMON,
			NAME_SPACE.ANDORRA_CIGAR_SHOP,
			NAME_SPACE.LEGAL_TERMS,
			NAME_SPACE.AUTH_RESULT,
			NAME_SPACE.COOKIE_POLICY,
			NAME_SPACE.SCAN_RATINGS,
		],
		defaultNS: NAME_SPACE.COMMON,
		fallbackNS: NAME_SPACE.COMMON,
		react: {
			wait: true,
			useSuspense: false,
		},
		interpolation: {
			escapeValue: false,
		},
		load: 'languageOnly',
	});

export default i18n;
