import apiHelper from 'services/apiHelper';

const createScan = async (
	tagId,
	uid,
	counter,
	country,
	tamperStatus,
	scanUrl,
	experienceId
) => {
	const api_url = localStorage.getItem('API_URL');
	const options = apiHelper.composeOptionsForAnonymousPost({
		tagId,
		uid,
		counter,
		country,
		tamperStatus,
		scanUrl,
		experienceId,
	});
	const url = `${api_url}/api/v1.0/scans`;

	const response = await fetch(url, options);
	if (response.ok) {
		const result = await response.json();
		return result;
	}

	throw response.statusText || 'Api error';
};

const updateScan = async ({ scanId, scanType, ageRange, gender }) => {
	const api_url = localStorage.getItem('API_URL');
	const options = apiHelper.composeOptionsForAnonymousPut({
		scanType,
		ageRange,
		gender,
	});
	const url = `${api_url}/api/v1.0/scans/${scanId}`;

	const response = await fetch(url, options);
	if (response.ok) {
		const result = await response.json();
		return result;
	}

	throw response.statusText || 'Api error';
};

export default {
	createScan,
	updateScan,
};
