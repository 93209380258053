import React from 'react';
import { CustomDropdown } from 'components/custom-dropdown/custom-dropdown';
import { IconRating } from 'components/icon-rating/icon-rating';
import { select } from './rating.styles';
import { ratingTypes } from '../../../ratingTypes';

const Rating = ({ ratings = [], onChange, ratingIcons }) => {
	return (
		<div>
			{ratings.map((rating, index) => (
				<div style={{ marginTop: 10 }}>
					{rating.type === ratingTypes.SCORE ? (
						<IconRating
							key={index}
							label={rating.name}
							name={rating.id}
							value={rating.value || ''}
							onChange={onChange(rating.id, 'value')}
							{...ratingIcons}
						/>
					) : (
						<CustomDropdown
							key={index}
							label={rating.name}
							name={rating.id}
							options={rating.options ?? []}
							optionValueProperty={'reference'}
							optionLabelProperty={'name'}
							value={rating.value || ''}
							showEmptyOption
							showLabel
							onChange={onChange(rating.id, 'value')}
							style={select}
						/>
					)}
				</div>
			))}
		</div>
	);
};

export default Rating;
