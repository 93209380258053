import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { height } from 'assets/common.styles';
import { productImage } from '../product-info.styles';
import { rightPanel, group, label, rows } from './product-info-vertical.styles';
import { ProductFields } from '../product-fields';
import { templateHelper } from 'services';
import SocialButtons from '../social-buttons/social-buttons';
import ProductInfoHeader from '../product-info-header/product-info-header';
import ProductImageLink from '../product-image-link/product-image-link';

const ProductInfoVertical = (props) => {
	const {
		productName,
		productFields,
		template,
		mediaFiles,
		token,
		setViewByName,
		fontColor,
		topIcons,
		reference,
		showTitle,
	} = props;
	const [leftImageUrl, leftImageTargetLink] =
		templateHelper.getImageFromTemplate(
			template,
			mediaFiles,
			'VerticalLeft',
			token
		);

	return (
		<Container className='row'>
			<ProductInfoHeader
				productName={productName}
				showTitle={showTitle}
				setViewByName={setViewByName}
				showBackButton={false}
				fontColor={fontColor}
				topIcons={topIcons}
				previousViewReference={reference}
			/>

			<Row style={rows}>
				<Col
					xs='4'
					style={{
						marginLeft: 20,
						marginRight: height > 640 ? 25 : 15,
						paddingTop: 15,
					}}
				>
					{leftImageUrl && (
						<ProductImageLink
							imageUrl={leftImageUrl}
							imageTargetLink={leftImageTargetLink}
							title={productName || 'Product'}
							style={productImage}
						/>
					)}
				</Col>
				<Col xs='8' style={rightPanel}>
					<div style={group}>
						<span style={{ ...label, fontWeight: 100 }}>&nbsp;</span>
					</div>
					<ProductFields
						fontColor={fontColor}
						fields={productFields ? productFields : []}
					/>
				</Col>
			</Row>
			<SocialButtons {...props} />
		</Container>
	);
};

export default ProductInfoVertical;
