import React from 'react';
import { height } from 'assets/common.styles';
import { socialContainer, socialIcon } from './social-buttons.styles';

const SocialButtons = (props) => {
	const { bottomIcons } = props;

	return (
		<div style={socialContainer}>
			{bottomIcons.map((icon, key) =>
				icon.targetLink ? (
					<div key={key} style={socialIcon}>
						<a target='blank' href={icon.targetLink}>
							<img
								width={height > 640 ? 40 : 30}
								heigth={height > 640 ? 40 : 30}
								src={icon.imageUrl}
								alt='Humidif Group'
							/>
						</a>
					</div>
				) : (
					<div key={key} style={socialIcon}>
						<img
							width={height > 640 ? 40 : 30}
							heigth={height > 640 ? 40 : 30}
							src={icon.imageUrl}
							alt='Offers'
							onClick={() => props.setViewByName('ProductImageView', icon.id)}
						/>
					</div>
				)
			)}
		</div>
	);
};

export default SocialButtons;
