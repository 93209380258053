const select = {
	padding: 10,
	borderRadius: 30,
	width: '100%',
	fontFamily: 'inherit',
};

const label = {
	lineHeight: '1.5rem',
};

export { select, label };
