import React from 'react';
import { height } from 'assets/common.styles';

const ProductInfoHeaderButton = (props) => {
	return (
		<div>
			<img
				width={height > 640 ? 40 : 30}
				heigth={height > 640 ? 40 : 30}
				src={props.image}
				alt={props.alt}
				onClick={props.onClick}
			/>
		</div>
	);
};

export default ProductInfoHeaderButton;
