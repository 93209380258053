import React from 'react';

const ProductImageLink = (props) => {
	const { title, imageUrl, imageTargetLink, style } = props;

	return imageTargetLink ? (
		<a href={imageTargetLink} target='_blank'>
			<img src={imageUrl} alt={title} title={title} style={style} />
		</a>
	) : (
		<img src={imageUrl} alt={title} style={style} />
	);
};

export default ProductImageLink;
