export const resources = {
	ca: {
		common: require('./ca/common.json'),
		andorraCigarShop: require('./ca/andorraCigarShop.json'),
		authResult: require('./ca/authResult.json'),
		legalTerms: require('./ca/legalTerms.json'),
		cookiePolicy: require('./ca/cookiePolicy.json'),
		scanRatings: require('./ca/scanRatings.json'),
	},
	en: {
		common: require('./en/common.json'),
		andorraCigarShop: require('./en/andorraCigarShop.json'),
		authResult: require('./en/authResult.json'),
		legalTerms: require('./en/legalTerms.json'),
		cookiePolicy: require('./en/cookiePolicy.json'),
		scanRatings: require('./en/scanRatings.json'),
	},
	es: {
		common: require('./es/common.json'),
		andorraCigarShop: require('./es/andorraCigarShop.json'),
		authResult: require('./es/authResult.json'),
		legalTerms: require('./es/legalTerms.json'),
		cookiePolicy: require('./es/cookiePolicy.json'),
		scanRatings: require('./es/scanRatings.json'),
	},
	fr: {
		common: require('./fr/common.json'),
		andorraCigarShop: require('./fr/andorraCigarShop.json'),
		authResult: require('./fr/authResult.json'),
		legalTerms: require('./fr/legalTerms.json'),
		cookiePolicy: require('./fr/cookiePolicy.json'),
		scanRatings: require('./fr/scanRatings.json'),
	},
};
