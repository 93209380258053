const card = {
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	minWidth: 0,
	wordWrap: 'break-word',
	marginBottom: 5,
};

const cardBody = {
	flex: '1 1 auto',
	minHeight: '1px',
	padding: '1.25rem',
	paddingTop: 0,
	whiteSpace: 'pre-line',
};

export { card, cardBody };
