import ReactGA from 'react-ga4';

function init() {
	const analyticsKey = localStorage.getItem('GOOGLE_ANALYTICS_KEY');

	ReactGA.initialize(
		analyticsKey /*, {
		debug: true
	}*/
	);
}

export default { init };
