import React from 'react';
import { group, label, value } from './product-field.styles';

const ProductField = (props) => {
	const { field, fontColor } = props;
	return (
		<div style={group}>
			<span style={{ ...label, color: fontColor }}>{`${field.title}: `}</span>
			<span style={{ ...value, color: fontColor }}>{`${field.value}`}</span>
		</div>
	);
};

export default ProductField;
