import apiHelper from 'services/apiHelper';
import { stringHelper } from './string.helper';

const createRating = async (ratingsModel) => {
	const api_url = localStorage.getItem('API_URL');
	const options = apiHelper.composeOptionsForAnonymousPost({
		...ratingsModel,
	});
	const url = `${api_url}/api/v1.0/scan-ratings`;

	const response = await fetch(url, options);
	if (response.ok) {
		return true;
	}

	return response.text().then((text) => {
		throw new Error(stringHelper.normalizeQuotes(text));
	});
};

const getRatings = async (scanId) => {
	const api_url = localStorage.getItem('API_URL');
	const options = apiHelper.composeOptionsForAnonymousGet();
	const url = `${api_url}/api/v1.0/scan-ratings/${scanId}`;

	const response = await fetch(url, options);
	if (response.ok) {
		const result = await response.json();
		return result;
	}

	return response.text().then((text) => {
		throw new Error(stringHelper.normalizeQuotes(text));
	});
};

export default {
	createRating,
	getRatings,
};
