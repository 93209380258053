import { useCallback, useMemo, useState } from 'react';
import { productJsonFieldNames } from './productJsonFieldNames';

export function useData(data, brand) {
	const [selectedProduct, setSelectedProduct] = useState(null);

	const brands = useMemo(() => {
		if (!data) return [];
		var aux = data
			.filter((row) => row[productJsonFieldNames.MARCA] !== '')
			.map((row) => row[productJsonFieldNames.MARCA]);

		return [...new Set(aux)];
	}, [data]);

	const brandProducts = useMemo(() => {
		if (!brand) return [];

		var filteredProducts = data.filter(
			(row) =>
				row[productJsonFieldNames.MARCA] !== '' &&
				row[productJsonFieldNames.MARCA] === brand
		);

		return filteredProducts;
	}, [data, brand]);

	const getProduct = useCallback(
		(productId) => {
			var filteredProducts = data.filter(
				(row) =>
					row[productJsonFieldNames.CODIGO] !== '' &&
					row[productJsonFieldNames.CODIGO] === productId
			);

			setSelectedProduct(filteredProducts[0]);
		},
		[data]
	);

	return {
		brands,
		brandProducts,
		getProduct,
		selectedProduct,
	};
}
