const modal = ({
	fontColor,
	backgroundColor = '#000000',
	backgroundImage,
	fontFamily,
}) => {
	const modalBackgroundColor =
		backgroundColor && backgroundColor.length > 7
			? backgroundColor
			: `${backgroundColor}f2`;
	return {
		position: 'fixed',
		zIndex: 1,
		left: 0,
		top: 0,
		height: '100%',
		width: '100%',
		overflow: 'auto',
		color: fontColor,
		fontFamily: fontFamily,
		backgroundColor: modalBackgroundColor,
		backgroundImage: backgroundImage ? 'url(' + backgroundImage + ')' : 'none',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		display: 'flex',
		flexDirection: 'column',
		letterSpacing: 2,
	};
};

const title = ({ titleFontColor, fontColor }) => {
	return {
		marginTop: 20,
		textAlign: 'center',
		color: titleFontColor || fontColor,
	};
};

const buttonsContainer = {
	display: 'flex',
	justifyContent: 'space-around',
	marginTop: 20,
	alignItems: 'center',
};
const buttonsContainerVertical = {
	...buttonsContainer,
	flexDirection: 'column',
};

const button = ({ fontColor, fontFamily }) => {
	return {
		padding: 10,
		borderRadius: 30,
		background: 'transparent',
		letterSpacing: 2,
		color: fontColor,
		borderColor: fontColor,
		fontFamily: fontFamily,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	};
};

const shareButton = ({ fontColor, fontFamily }) => {
	return {
		...button({ fontColor, fontFamily }),
		whiteSpace: 'unset',
		textOverflow: 'unset',
		maxWidth: '100%',
		marginBottom: 15,
	};
};

export {
	modal,
	title,
	button,
	shareButton,
	buttonsContainer,
	buttonsContainerVertical,
};
