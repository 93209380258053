function parseFormData(form) {
	try {
		return Object.fromEntries(
			Object.values(form).map((a) => [[a.name], a.value])
		);
	} catch {
		return {};
	}
}

export const formHelper = {
	parseFormData,
};
