import React from 'react';
import { mainDiv } from './product-info.styles';
import { templateTypes } from './templateTypes';
import ProductInfoVertical from './product-info-vertical/product-info-vertical';
import ProductInfoHorizontal from './product-info-horizontal/product-info-horizontal';
import ProductInfoImages from './product-info-images/product-info-images';
import { ProductInfo as ProductInfoAndorraCigarShop } from './andorra-cigar-shop/product-info';
import CookiePolicyLink from '../cookie-policy/cookie-policy-link';

const ProductInfo = (props) => {
	const { reference, backgroundImageUrl, backgroundColor, fontColor } = props;

	return (
		<div
			id='maindiv'
			style={mainDiv('100vh', backgroundImageUrl, backgroundColor, fontColor)}
		>
			{reference === templateTypes.HORIZONTAL ? (
				<ProductInfoHorizontal {...props} />
			) : reference === templateTypes.VERTICAL ? (
				<ProductInfoVertical {...props} />
			) : reference === templateTypes.CIGAR_SHOP_ANDORRA ? (
				<ProductInfoAndorraCigarShop {...props} />
			) : (
				<ProductInfoImages {...props} />
			)}
			<CookiePolicyLink
				modalStyles={{
					fontColor: props.fontColor,
					backgroundColor: props.backgroundColor,
				}}
			/>
		</div>
	);
};

export default ProductInfo;
