import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { content } from './product-info.styles';
import ProductInfoHeader from './product-info-header/product-info-header';
import { useData } from './useData';
import { ProductPrice } from './product-price';
import { ProductFilters } from './product-filters';
import data from 'assets/json/andorra-cigar-shop.json';

const ACTIVE_VIEW_PRODUCT_SEARCH = 0;
const ACTIVE_VIEW_PRODUCT_PRICE = 1;

export const ProductInfo = (props) => {
	const { fontColor, backgroundColor } = props;

	const [inputs, setInputs] = useState({});
	const [activeView, setActiveView] = useState(ACTIVE_VIEW_PRODUCT_SEARCH);
	const { brands, brandProducts, getProduct, selectedProduct } = useData(
		data,
		inputs.marca
	);

	const handleChange =
		(fieldName) =>
		({ target }) => {
			let newInputs = { [fieldName]: target.value };
			if (fieldName === 'marca') {
				newInputs.codigo = '';
			}
			setInputs((prevInputs) => ({ ...prevInputs, ...newInputs }));
		};

	const handleSubmit = () => {
		getProduct(inputs.codigo);
		setActiveView(ACTIVE_VIEW_PRODUCT_PRICE);
	};

	const handleBackButtonClick = () => {
		setActiveView(ACTIVE_VIEW_PRODUCT_SEARCH);
	};

	return (
		<Container className='row' style={content}>
			<ProductInfoHeader
				fontColor={fontColor}
				backgroundColor={backgroundColor}
				showBackButton={activeView === ACTIVE_VIEW_PRODUCT_PRICE}
				onBackButtonClick={handleBackButtonClick}
			/>
			{activeView === ACTIVE_VIEW_PRODUCT_PRICE ? (
				<ProductPrice
					product={selectedProduct}
					fontColor={fontColor}
					country={inputs.country}
				/>
			) : (
				<ProductFilters
					inputs={inputs}
					onChange={handleChange}
					onSubmit={handleSubmit}
					backgroundColor={backgroundColor}
					brands={brands}
					brandProducts={brandProducts}
				/>
			)}
		</Container>
	);
};
