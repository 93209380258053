import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import {
	background,
	container,
	header,
	confirmContainer,
	confirmText,
	button,
	cookiePolicyBottomLink,
} from './legal-terms.styles';
import CookiePolicyConsent from '../cookie-policy/cookie-policy-consent';
import CookiePolicyLink from '../cookie-policy/cookie-policy-link';

const LegalTerms = (props) => {
	const { fontColor, backgroundColor, cookiesEnabled, legalAge } = props;
	const [cookiesRejected, setCookiesRejected] = useState(false);
	const { t } = useTranslation(NAME_SPACE.LEGAL_TERMS);

	const handleRejectCookies = () => {
		setCookiesRejected(true);
	};

	return (
		<div
			style={{
				...background,
				backgroundColor: props.backgroundColor,
				backgroundImage: props.backgroundImageUrl
					? `url(${props.backgroundImageUrl})`
					: 'none',
				color: fontColor,
			}}
		>
			<div style={container}>
				<h1 style={{ ...header, color: fontColor }}>{t('title')}</h1>
				<div style={confirmContainer}>
					<p style={{ ...confirmText, color: fontColor }}>
						{t('confirmMessage', {
							legalAge,
						})}
					</p>
					{/* Investigate how to change button styles to set fontcolor!! */}
					<Button
						style={{ ...button, color: fontColor, borderColor: fontColor }}
						disabled={cookiesRejected}
						onClick={() => {
							props.setNextView();
						}}
					>
						{t('yes')}
					</Button>
					<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
					<Button
						style={{ ...button, color: fontColor, borderColor: fontColor }}
						disabled={cookiesRejected}
						onClick={() => {
							window.close();
						}}
					>
						{t('no')}
					</Button>
				</div>
			</div>

			<div style={cookiePolicyBottomLink}>
				<CookiePolicyLink
					modalStyles={{
						fontColor: fontColor,
						backgroundColor: backgroundColor,
					}}
				/>
			</div>
			{cookiesEnabled && (
				<CookiePolicyConsent {...props} onRejectCookies={handleRejectCookies} />
			)}
		</div>
	);
};

export default LegalTerms;
