import React, { useMemo } from 'react';
import { StarHalf, StarEmpty, StarFull } from 'assets/img';
import {
	container,
	iconContainer,
	iconRow,
	icon,
	sliderContainer,
	slider,
	labelContainer,
	label as labelStyle,
} from './icon-rating.styles';

export const IconRating = ({
	label,
	name,
	value,
	onChange,
	maxOptions = 5,
	allowHalfValues = true,
	emptyIcon,
	halfIcon,
	fullIcon,
}) => {
	const options = useMemo(() => {
		let tempOptions = new Array(maxOptions).fill(0).map(function (num, index) {
			return index + 1;
		});
		return tempOptions;
	}, [maxOptions]);

	return (
		<div style={{ ...container }}>
			<div style={{ ...labelContainer }}>
				<label style={{ ...labelStyle }}>{label}</label>
			</div>
			<div
				style={{
					...iconContainer,
				}}
			>
				<div style={{ ...iconRow }}>
					{options.map((o) => (
						<>
							{o <= value ? (
								<img
									src={fullIcon || StarFull}
									style={{ ...icon, width: 100 / maxOptions }}
								/>
							) : allowHalfValues &&
							  !Number.isInteger(parseFloat(value)) &&
							  o - 0.5 <= value ? (
								<img
									src={halfIcon || StarHalf}
									style={{ ...icon, width: 100 / maxOptions }}
								/>
							) : (
								<img
									src={emptyIcon || StarEmpty}
									style={{ ...icon, width: 100 / maxOptions }}
								/>
							)}
						</>
					))}
				</div>
				<div
					style={{
						...sliderContainer,
					}}
				>
					<input
						name={name}
						type='range'
						value={value || 0}
						min={0}
						max={maxOptions}
						step={allowHalfValues ? 0.5 : 1}
						style={{ ...slider }}
						onChange={onChange}
					/>
				</div>
			</div>
		</div>
	);
};
