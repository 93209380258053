import React from 'react';
import { Label, Input } from 'reactstrap';
import { select, label as labelStyle } from './custom-dropdown.styles';

export const CustomDropdown = (props) => {
	const {
		name = 'customSelect',
		label,
		options,
		optionValueProperty,
		optionLabelProperty,
		showEmptyOption,
		showLabel = false,
		onChange,
		value = '',
		style,
	} = props;

	return (
		<>
			{showLabel && (
				<Label for={name} style={{ ...labelStyle }}>
					{label}
				</Label>
			)}
			<Input
				type='select'
				name={name}
				id={name}
				onChange={onChange}
				style={{ ...select, ...style }}
				value={value}
			>
				<option value='' disabled={!showEmptyOption} hidden={!showEmptyOption}>
					{label}
				</option>
				{options?.map((option, index) => {
					return (
						<option
							key={`option-${index}`}
							value={optionValueProperty ? option[optionValueProperty] : option}
						>
							{optionLabelProperty ? option[optionLabelProperty] : option}
						</option>
					);
				})}
			</Input>
		</>
	);
};
