import React from 'react';
import { card, cardBody } from './rating-result-message.styles';

export const RatingResultMessage = ({ message }) => {
	return (
		<div style={{ ...card }}>
			<div style={{ ...cardBody }}>{message}</div>
		</div>
	);
};
