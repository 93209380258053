import React, { useCallback, useEffect, useState } from 'react';
import { useEventListener } from '../../../hooks';
import { background } from './product-custom-page.styles';
import ProductCustomPageContent from './product-custom-page-content';
import CookiePolicy from '../../cookie-policy/cookie-policy';
import ProductRatings from '../product-ratings/product-ratings';
import {
	useProductRatingsForm,
	useReplaceableRatings,
} from '../product-ratings';
import { useCustomAnalyticsForm } from '../product-ratings/useCustomAnalyticsForm';
import { formHelper } from '../../../services';
import ProductCustomShareableContent from './product-custom-shareable-content';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';

const ProductCustomPage = (props) => {
	const {
		content,
		setViewByName,
		reference,
		token,
		articles,
		pages,
		scanId,
		scanType,
		ratingIcons,
		ratingCompletedResultType,
		getViewContent,
	} = props;
	const [isCookiesModalOpen, setIsCookiesModalOpen] = useState(false);
	const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
	const [shareableContent, setShareableContent] = useState();
	const { t } = useTranslation(NAME_SPACE.SCAN_RATINGS);
	const {
		ratingsResult,
		errors: ratingErrors,
		ratingsData,
	} = useProductRatingsForm(scanId, articles, ratingCompletedResultType);
	const { handleSubmit: handleSubmitAnalytics } = useCustomAnalyticsForm(
		scanId,
		scanType
	);

	const { replaceRatings } = useReplaceableRatings(articles, props.mediaFiles);

	const handler = useCallback(
		({ target }) => {
			const linkElement = target?.closest('a');
			if (linkElement?.hasAttribute('data-cookies-button') || false) {
				setIsRatingsModalOpen(false);
				setIsCookiesModalOpen(true);
			} else if (
				linkElement?.hasAttribute('data-ratings-popup-button') ||
				false
			) {
				setIsCookiesModalOpen(false);
				setIsRatingsModalOpen(true);
			} else if (linkElement?.hasAttribute('data-target-share') || false) {
				let htmlData = null;
				const targetPage = linkElement.getAttribute('data-target-share');
				if (targetPage) {
					htmlData = getViewContent(null, 'Default Page', targetPage)?.props
						?.content;
				} else {
					htmlData = linkElement.parentElement?.querySelector(
						'main.share-container'
					)?.outerHTML;
				}
				if (htmlData) {
					setShareableContent(htmlData);
				}
			} else if (linkElement?.hasAttribute('data-target') || false) {
				const targetPage = linkElement.getAttribute('data-target');
				const linkType = linkElement.getAttribute('data-type');

				const form = target.closest('form');
				if (linkType === 'submit' && form) {
					const requireds = Array.from(form.querySelectorAll('[required]'));
					const isValid = requireds.every((required) => required.value);
					if (isValid) {
						const formData = formHelper.parseFormData(form);
						handleSubmitAnalytics({ ...formData });
						setViewByName(null, reference, targetPage);
						window.scrollTo(0, 0);
					}
				} else {
					if (linkType === 'next' && reference === 'AuthResult') {
						props.authenticationResult ? props.setNextView() : window.close();
					} else {
						setViewByName(null, reference, targetPage);
						window.scrollTo(0, 0);
					}
				}
			}
		},
		[reference, setViewByName, setIsCookiesModalOpen]
	);

	useEventListener('click', handler);

	const onShareRatings = useCallback(
		(ratingsTargetPage) => {
			let htmlData = null;
			let content = null;
			if (ratingsTargetPage) {
				htmlData = getViewContent(null, 'Default Page', ratingsTargetPage)
					?.props?.content;
			}
			if (htmlData) {
				content = replaceRatings(htmlData, ratingsData);
			} else {
				alert(t('share.error'));
			}

			if (content) {
				setShareableContent(content);
			} else {
				alert(t('share.noVoteToShare'));
			}
		},
		[ratingsResult, ratingsData]
	);

	function resetShareableContent() {
		setShareableContent('');
	}

	return (
		<div
			style={{
				...background,
			}}
		>
			<ProductCustomPageContent content={content} token={token} />
			<CookiePolicy
				isModalOpen={isCookiesModalOpen}
				fontColor={'white'}
				backgroundColor={'#000000bf'}
				onCloseModal={() => setIsCookiesModalOpen(false)}
			/>
			{isRatingsModalOpen && (
				<ProductRatings
					isModalOpen={isRatingsModalOpen}
					onCloseModal={() => setIsRatingsModalOpen(false)}
					onShareRatings={onShareRatings}
					isModal
					ratingIcons={ratingIcons}
					ratingsResult={ratingsResult}
					ratingErrors={ratingErrors}
					{...props}
				/>
			)}
			{shareableContent && (
				<ProductCustomShareableContent
					content={shareableContent}
					token={token}
					onFinishShare={resetShareableContent}
				/>
			)}
		</div>
	);
};

export default ProductCustomPage;
