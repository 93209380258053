import { height } from 'assets/common.styles';

const group = {
	marginBottom: height > 640 ? 20 : 15,
	textAlign: 'left',
	marginLeft: 45,
	marginRight: 45,
};

const label = {
	fontWeight: 'bold',
	wordBreak: 'break-word',
};

const value = {
	...label,
	fontWeight: '100',
};

export { group, label, value };
