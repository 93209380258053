import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import {
	card,
	cardTitle,
	title,
	button,
	cardBody,
	description,
	productImage,
} from './product-article.styles';
import Rating from './rating/rating';
import { CollpaseButton } from '../../../../components';
import ProductImageLink from '../../product-image-link/product-image-link';

export const ProductArticle = ({
	article,
	ratingIcons,
	fontColor,
	subtitleFontColor,
}) => {
	const [articleInputs, setArticleInputs] = useState({ ...article });
	const [collapsed, setCollapsed] = useState(true);
	const { t } = useTranslation(NAME_SPACE.SCAN_RATINGS);

	function toggle() {
		setCollapsed((prevCollapsed) => !prevCollapsed);
	}

	const handleChange =
		(ratingId, fieldName) =>
		({ target }) => {
			setArticleInputs((prevInputs) => ({
				...prevInputs,
				ratingItems: (prevInputs.ratingItems || []).map((r) => {
					return r.id === ratingId ? { ...r, [fieldName]: target.value } : r;
				}),
			}));
		};

	return (
		<div style={card(fontColor)}>
			<div style={{ ...cardTitle }} onClick={() => toggle()}>
				<span style={{ ...title(subtitleFontColor, fontColor) }}>
					{articleInputs.name}
				</span>
				<CollpaseButton
					style={{ ...button }}
					isCollapsed={collapsed}
					fontColor={fontColor}
				/>
			</div>
			{collapsed && (
				<>
					{articleInputs.imageTag && articleInputs.imageUrl && (
						<div>
							<ProductImageLink
								title={articleInputs.name}
								imageUrl={articleInputs.imageUrl}
								style={{ ...productImage }}
							/>
						</div>
					)}
					<div style={{ ...cardBody }}>
						{articleInputs.description && (
							<div>
								<p style={{ ...description }}>{articleInputs.description}</p>
							</div>
						)}
						{articleInputs.ratingItems?.length ? (
							<Rating
								ratings={articleInputs.ratingItems}
								onChange={handleChange}
								ratingIcons={ratingIcons}
							/>
						) : (
							<span>{t('nothingToReview')}</span>
						)}
					</div>
				</>
			)}
		</div>
	);
};
