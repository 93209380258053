import React from 'react';
import { Row, Col } from 'reactstrap';
import ProductInfoHeaderBackButton from '../product-info-header/product-info-header-back-button';
import {
	titleRow,
	headerBackButtonColumn,
	headerProductNameColumn,
	headerLogo,
} from './product-info-header.styles';
import { CigarShopAndorraLogo } from 'assets/img';

const ProductInfoHeader = (props) => {
	const { showBackButton, onBackButtonClick } = props;

	return (
		<Row style={titleRow}>
			<Col xs={2} style={headerBackButtonColumn}>
				{showBackButton && (
					<ProductInfoHeaderBackButton
						{...props}
						alt='Back'
						onClick={onBackButtonClick}
					/>
				)}
			</Col>
			<Col xs={8} style={headerProductNameColumn}>
				<img src={CigarShopAndorraLogo} style={headerLogo} alt='Logo' />
			</Col>
			<Col xs={2} style={headerBackButtonColumn}></Col>
		</Row>
	);
};

export default ProductInfoHeader;
