import React from 'react';

const ProductInfoHeaderBackButton = (props) => {
	return (
		<div>
			<svg height={30} width={30} onClick={props.onClick}>
				<polyline
					points='15,0 7,15 15,30'
					fill='none'
					stroke={props.fontColor}
					strokeWidth='2'
				/>
				<span style={{ fontColor: props.fontColor }} onClick={props.onClick}>
					&lt;
				</span>
			</svg>
		</div>
	);
};

export default ProductInfoHeaderBackButton;
