import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Button } from 'reactstrap';
import { pageTypeReferences } from '../pageTypes';
import { ProductArticle } from './product-article';
import { RatingResults } from './rating-results/rating-results';
import {
	title,
	button,
	shareButton,
	modal,
	buttonsContainer,
	buttonsContainerVertical,
} from './product-ratings.styles';

const ProductRatings = (props) => {
	const {
		articles,
		pages,
		isModal,
		onCloseModal,
		mediaFiles,
		ratingsResult,
		ratingIcons,
		ratingErrors,
		scanId,
		ratingCompletedResultType,
		ratingGratutideMessage,
		ratingShareTargetTemplatePageReference,
		ratingShareButtonTitle,
		onShareRatings,
	} = props;
	const { t } = useTranslation(NAME_SPACE.COMMON);

	const pageConfig = useMemo(() => {
		let ratingsPage = pages.filter(
			(p) => p.reference === pageTypeReferences.RATINGS
		)[0];
		if (ratingsPage) {
			const mediaFile = (mediaFiles || []).filter(
				(ti) => ti.tag === ratingsPage.backgroundImageTag
			)[0];
			ratingsPage.backgroundImage =
				mediaFile && `${mediaFile.blobUrl}?${props.token}`;
			ratingsPage.backgroundImage =
				mediaFile && `${mediaFile.blobUrl}?${props.token}`;
		}
		return ratingsPage;
	}, [pages, mediaFiles]);

	return (
		<>
			{pageConfig && pageConfig.visible && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						...(isModal &&
							modal({
								...pageConfig,
							})),
					}}
				>
					{pageConfig.title && (
						<h3 style={title({ ...pageConfig })}>{pageConfig.title}</h3>
					)}

					<form
						data-form-ratings
						autoComplete='off'
						action=''
						style={{
							padding: 10,
						}}
					>
						<div>
							{!ratingsResult ? (
								(articles || []).map((article, index) => (
									<ProductArticle
										key={index}
										article={article}
										collapsible={isModal}
										ratingIcons={ratingIcons}
										fontColor={pageConfig.fontColor}
										subtitleFontColor={pageConfig.subtitleFontColor}
									/>
								))
							) : (
								<RatingResults
									articles={articles}
									ratingResults={ratingsResult}
									ratingIcons={ratingIcons}
									ratingErrors={ratingErrors}
									ratingCompletedResultType={ratingCompletedResultType}
									ratingGratutideMessage={ratingGratutideMessage}
									styles={{
										fontColor: pageConfig.fontColor,
										subtitleFontColor: pageConfig.subtitleFontColor,
										backgroundColor: pageConfig.backgroundColor,
									}}
								/>
							)}
						</div>
						<div
							style={{
								...(scanId &&
								ratingsResult &&
								ratingShareTargetTemplatePageReference
									? buttonsContainerVertical
									: buttonsContainer),
							}}
						>
							{scanId &&
								ratingsResult &&
								ratingShareTargetTemplatePageReference && (
									<Button
										onClick={() =>
											onShareRatings(ratingShareTargetTemplatePageReference)
										}
										style={shareButton({
											fontColor: isModal ? pageConfig.fontColor : 'inherit',
											fontFamily: isModal ? pageConfig.fontFamily : 'inherit',
											marginBottom: 20,
										})}
									>
										{ratingShareButtonTitle || t('actions.share')}
									</Button>
								)}
							{onCloseModal && (
								<Button
									style={button({
										fontColor: isModal ? pageConfig.fontColor : 'inherit',
										fontFamily: isModal ? pageConfig.fontFamily : 'inherit',
									})}
									onClick={onCloseModal}
								>
									{t('actions.close')}
								</Button>
							)}
							{scanId && !ratingsResult && (
								<Button
									data-send-ratings
									style={button({
										fontColor: isModal ? pageConfig.fontColor : 'inherit',
										fontFamily: isModal ? pageConfig.fontFamily : 'inherit',
									})}
								>
									{t('actions.send')}
								</Button>
							)}
						</div>
					</form>
				</div>
			)}
		</>
	);
};

export default ProductRatings;
