import React, { useMemo } from 'react';
import { replacePageContentHelper } from '../../services';
import ProductCustomPage from '../product-info/product-custom-page/product-custom-page';
import AuthResultDefault from './authResultDefault';

const AuthResult = (props) => {
	const { pages, mediaFiles } = props;

	const content = useMemo(() => {
		const htmlContent = pages.filter(
			(p) => p.contentPageReference === 'AuthResult'
		)[0]?.content;

		if (!htmlContent) {
			return null;
		}

		return replacePageContentHelper.replaceFields(
			htmlContent,
			replacePageContentHelper.fillReplaceableFields({ ...props }),
			mediaFiles
		);
	}, [pages, mediaFiles]);

	return content ? (
		<ProductCustomPage
			content={content}
			token={props.token}
			setViewByName={props.token}
			reference={props.reference}
			articles={props.articles}
			pages={props.pages}
			getViewContent={props.getViewContent}
			authenticationResult={props.authenticationResult}
			setNextView={props.setNextView}
		/>
	) : (
		<AuthResultDefault {...props} />
	);
};

export default AuthResult;
