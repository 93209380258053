import { height, width, color } from 'assets/common.styles';

const background = {
	width: width,
	height: height,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	letterSpacing: 2,
};

const container = {
	position: 'fixed',
	width: 400,
	top: 20,
	left: window.screen.width / 2,
	marginLeft: -200,
};

const button = {
	width: '30%',
	padding: 10,
	borderColor: color,
	background: 'transparent',
	color: color,
	letterSpacing: 2,
};

const header = {
	marginTop: 20,
	color: color,
};

const confirmContainer = {
	marginTop: height / 3,
};

const confirmText = {
	color: color,
	paddingTop: 10,
	fontSize: 20,
};

const cookiePolicyBottomLink = {
	position: 'fixed',
	bottom: 0,
	width: '100%',
};

export {
	background,
	container,
	header,
	confirmContainer,
	confirmText,
	button,
	cookiePolicyBottomLink,
};
