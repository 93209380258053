import { height, width } from 'assets/common.styles';

const background = {
	width: '100%',
	height: '100vh',
	letterSpacing: 2,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
};

const img = {
	padding: '1.35rem 0px',
	objectFit: 'contain',
	width: height > width ? '40%' : '20%',
};

const text = {
	padding: '1.35rem',
	fontSize: 20,
	overflowY: 'overlay',
};

export { background, img, text };
