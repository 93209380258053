import React, { useEffect } from 'react';
import html2canvas from 'html2canvas';
import SanitizedHTML from 'react-sanitized-html';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { sanitizedHtmlConfig } from './sanitizedHtmlConfig';

const ProductCustomShareableContent = (props) => {
	const { content, token, onFinishShare } = props;
	const { t } = useTranslation(NAME_SPACE.SCAN_RATINGS);

	function shareContent() {
		const htmlData = document
			.getElementById('content')
			?.querySelector('main.share-container');

		if (htmlData) {
			html2canvas(htmlData, {
				scale: 1,
				letterRendering: 1,
				allowTaint: true,
				useCORS: true,
			}).then(async (canvas) => {
				const contentDataURL = canvas.toDataURL('image/png');
				if (!contentDataURL) {
					return;
				}
				const blob = await fetch(contentDataURL).then((r) => r.blob());
				var files = [
					new File([blob], 'share.png', {
						type: blob.type,
						lastModified: new Date().getTime(),
					}),
				];

				const data = {
					files: files,
				};
				try {
					if (navigator.canShare && navigator.canShare(data)) {
						await navigator.share(data);
					} else {
						alert(t('share.cannotShare'));
					}
				} catch {}
			});
		} else {
			alert(t('share.error'));
		}

		if (onFinishShare) {
			onFinishShare();
		}
	}

	useEffect(() => {
		if (content) {
			shareContent();
		}
	}, [content]);

	return (
		<div
			id='content'
			style={{
				height: 0,
				width: 0,
				overflow: 'hidden',
			}}
		>
			<SanitizedHTML
				html={content?.replaceAll('#TOKEN#', `?${token}`)}
				{...sanitizedHtmlConfig}
				allowedAttributes={null}
			/>
		</div>
	);
};

export default ProductCustomShareableContent;
