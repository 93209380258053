import tagsApi from '../../../services/tagsApi';

export function useCustomAnalyticsForm(scanId, scanType) {
	async function handleSubmit({ ageRange, gender }) {
		try {
			if (scanId) {
				await tagsApi.updateScan({
					scanId: scanId,
					scanType: scanType,
					ageRange: ageRange,
					gender: gender,
				});
			}
		} catch (err) {}
	}

	return {
		handleSubmit,
	};
}
