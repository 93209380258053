import React from 'react';

export const CollpaseButton = ({
	isCollapsed,
	onClick,
	style,
	fontColor = 'black',
}) => {
	return (
		<div style={{ ...style }}>
			<svg height={30} width={30} onClick={onClick}>
				{isCollapsed ? (
					<polyline
						points='0,15 15,21 30,15'
						fill='none'
						stroke={fontColor}
						strokeWidth='2'
					/>
				) : (
					<polyline
						points='15,0 21,15 15,30'
						fill='none'
						stroke={fontColor}
						strokeWidth='2'
					/>
				)}
				<span style={{ fontColor: fontColor }} onClick={onClick}>
					{isCollapsed ? '<' : '>'}
				</span>
			</svg>
		</div>
	);
};
