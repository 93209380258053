const card = (fontColor) => {
	return {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		minWidth: 0,
		wordWrap: 'break-word',
		backgroundColor: 'transparent',
		backgroundClip: 'border-box',
		border: fontColor
			? `1px solid ${fontColor}2e`
			: '1px solid rgba(0, 0, 0, 0.125)',
		borderRadius: '0.25rem',
		marginBottom: 5,
	};
};

const cardTitle = {
	padding: 10,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	fontSize: '1.3rem',
};

const title = (subtitleFontColor, fontColor) => {
	return {
		fontVariant: 'small-caps',
		fontSize: '1.3rem',
		fontWeight: 'bold',
		textOverflow: 'ellipsis',
		color: subtitleFontColor || fontColor,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	};
};

const description = {
	whiteSpace: 'pre-line',
};

const productImage = {
	maxWidth: '100%',
	width: '100%',
	objectFit: 'contain',
};

const button = {
	display: 'flex',
};

const cardBody = {
	flex: '1 1 auto',
	minHeight: '1px',
	padding: '1.25rem',
	paddingTop: 0,
};

export { card, cardTitle, title, description, productImage, button, cardBody };
