import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { content, productImage, rows } from '../product-info.styles';
import SocialButtons from '../social-buttons/social-buttons';
import { templateHelper } from 'services';
import ProductInfoHeader from '../product-info-header/product-info-header';
import ProductImageLink from '../product-image-link/product-image-link';

const ProductInfoImages = (props) => {
	const {
		productName,
		mediaFiles,
		pages,
		token,
		setViewByName,
		fontColor,
		topIcons,
		bottomIcons,
		reference,
		showTitle,
		authenticationData,
		tamperStatuses,
		experienceDefaultData,
	} = props;

	const imageUrls = templateHelper.getUnattachedImages(
		pages,
		mediaFiles,
		token,
		topIcons.concat(bottomIcons),
		authenticationData,
		tamperStatuses,
		experienceDefaultData
	);

	return (
		<>
			<Container
				className='row'
				style={{
					...content,
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
				}}
			>
				<ProductInfoHeader
					productName={productName}
					showTitle={showTitle}
					setViewByName={setViewByName}
					showBackButton={false}
					fontColor={fontColor}
					topIcons={topIcons}
					previousViewReference={reference}
				/>
				{imageUrls.map(([imageUrl, imageTargetLink], key) => (
					<Row key={key} style={rows}>
						<Col xs='12'>
							{imageUrl && (
								<ProductImageLink
									imageUrl={imageUrl}
									imageTargetLink={imageTargetLink}
									alt={productName || 'Product'}
									style={productImage}
								/>
							)}
						</Col>
					</Row>
				))}
			</Container>
			<SocialButtons {...props} />
		</>
	);
};

export default ProductInfoImages;
