const container = {
	display: 'flex',
	flexDirection: 'column',
	marginBottom: 5,
};

const iconContainer = {
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	height: 32,
	padding: 5,
};

const iconRow = {
	display: 'flex',
	flex: 1,
	height: '100%',
};

const icon = {
	flex: 1,
	objectFit: 'contain',
};

const sliderContainer = {
	display: 'flex',
	flex: 1,
	marginTop: '-2rem',
};

const slider = {
	opacity: 0,
	width: '100%',
};

const labelContainer = {
	display: 'flex',
};

const label = {
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
};

export {
	container,
	iconContainer,
	iconRow,
	icon,
	sliderContainer,
	slider,
	labelContainer,
	label,
};
