import React from 'react';
import { Row, Button } from 'reactstrap';
import { CustomDropdown } from 'components/custom-dropdown/custom-dropdown';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { filterRow, savingButton, select } from '../product-info.styles';
import { productJsonFieldNames } from '../productJsonFieldNames';

export const ProductFilters = (props) => {
	const { inputs, onChange, onSubmit, brands, brandProducts, backgroundColor } =
		props;
	const { t } = useTranslation(NAME_SPACE.ANDORRA_CIGAR_SHOP);
	return (
		<div>
			<Row style={filterRow}>
				<CustomDropdown
					label={t('productFilters.brand')}
					name='Marca'
					options={brands}
					value={inputs.marca}
					onChange={onChange('marca')}
					style={select}
				/>
			</Row>
			<Row style={filterRow}>
				<CustomDropdown
					label={t('productFilters.product')}
					name='Articulo'
					optionValueProperty={productJsonFieldNames.CODIGO}
					optionLabelProperty={productJsonFieldNames.ARTICULO}
					options={brandProducts}
					onChange={onChange('codigo')}
					value={inputs.codigo}
					style={select}
				/>
			</Row>
			<Row style={filterRow}>
				<CustomDropdown
					label={t('productFilters.country')}
					name='Country'
					optionValueProperty='value'
					optionLabelProperty='label'
					options={[
						{ value: 'Spain', label: t('productFilters.countries.spain') },
						{ value: 'France', label: t('productFilters.countries.france') },
					]}
					onChange={onChange('country')}
					value={inputs.country}
					style={select}
				/>
			</Row>
			<Row style={{ ...filterRow, marginTop: 75 }}>
				<Button
					disabled={!inputs['codigo'] || !inputs['country']}
					style={{
						...savingButton,
						color: backgroundColor,
						borderColor: backgroundColor,
					}}
					onClick={onSubmit}
				>
					{t('productFilters.savingPrice')}
				</Button>
			</Row>
		</div>
	);
};
