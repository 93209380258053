import React, { useEffect } from 'react';
import {
	HabanosLogo,
	TwitterLogo,
	YouTubeLogo,
	InstagramLogo,
	HGTagLogo,
	PackagingLogo,
	OffersLogo,
	TopIcon,
} from 'assets/img';
import 'assets/css/App.css';
import { useTemplateViews } from './hooks';
import { googleAnalytics, queryStringHelper, nfcParamHelper } from './services';
import { getCookieConsentValue } from 'react-cookie-consent';
import { demoModeLabel } from './App.styles';

const product = queryStringHelper.getParameterByName('productId');
const uid = nfcParamHelper.getUID(queryStringHelper.getParameterByName('uid'));
const counter = nfcParamHelper.getCounter(
	queryStringHelper.getParameterByName('uid')
);
const tamperStatus = queryStringHelper.getParameterByName('t');
const experienceId = queryStringHelper.getParameterByName('experienceId');

const renderActiveView = (activeView, props) => {
	const View = activeView?.view;
	const pageProps = activeView?.props;

	const page = pageProps?.authenticationResult
		? pageProps?.contentPageReference ?? pageProps?.reference ?? ''
		: 'NotAuthenticated';

	document.title = `${pageProps?.productName}${page && ' - ' + page}`;

	if (View) return <View {...props} {...pageProps} />;

	return '';
};

function App() {
	const {
		ready,
		views,
		activeView,
		setNextView,
		setViewByName,
		cookiesEnabled,
		getViewContent,
		demoMode,
	} = useTemplateViews(product, uid, counter, tamperStatus, experienceId);

	useEffect(() => {
		if (ready && !cookiesEnabled) {
			googleAnalytics.init();
		} else if (ready) {
			const isConsent = getCookieConsentValue();
			if (isConsent === 'true') {
				googleAnalytics.init();
			}
		}
	}, [ready, cookiesEnabled]);

	return (
		<div className='App'>
			<header className='App-header'>
				<div>
					{ready &&
						renderActiveView(views[activeView], {
							setNextView,
							setViewByName,
							getViewContent,
							HabanosLogo,
							TwitterLogo,
							InstagramLogo,
							HGTagLogo,
							YouTubeLogo,
							PackagingLogo,
							OffersLogo,
							TopIcon,
						})}
					{demoMode && <div style={demoModeLabel}>DEMO</div>}
				</div>
			</header>
		</div>
	);
}

export default App;
