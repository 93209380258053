import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { CigarShopAndorraFrance, CigarShopAndorraSpain } from 'assets/img';
import {
	fieldRow,
	fieldLabel,
	fieldValue,
	secondaryColor,
	roundImageFlag,
} from '../product-info.styles';

export const ProductPriceCountry = (props) => {
	const { country, originalPrice, savingPrice, savingPercent, fontColor } =
		props;
	const { t } = useTranslation(NAME_SPACE.ANDORRA_CIGAR_SHOP);
	return (
		<div
			style={{
				margin: '35px 35px 45px 35px',
			}}
		>
			<img
				src={
					country === 'France' ? CigarShopAndorraFrance : CigarShopAndorraSpain
				}
				style={roundImageFlag}
				alt={`${country} flag`}
			/>

			<Row style={fieldRow}>
				<Col xs='6' style={{ ...fieldLabel, color: fontColor }}>
					{t('productPrice.originalPrice')}
				</Col>
				<Col xs='6' style={{ ...fieldValue, color: fontColor }}>
					{originalPrice}
				</Col>
			</Row>
			<Row style={fieldRow}>
				<Col xs='6' style={{ ...fieldLabel, color: fontColor }}>
					{t('productPrice.savingPrice')}
				</Col>
				<Col xs='6' style={{ ...fieldValue, color: secondaryColor }}>
					{savingPrice}
				</Col>
			</Row>
			<Row style={fieldRow}>
				<Col xs='6' style={{ ...fieldLabel, color: fontColor }}>
					{t('productPrice.savingPercent')}
				</Col>
				<Col xs='6' style={{ ...fieldValue, color: secondaryColor }}>
					{savingPercent}
				</Col>
			</Row>
		</div>
	);
};
