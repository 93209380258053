const banner = ({ fontColor, backgroundColor }) => {
	return {
		backgroundColor: fontColor,
		color: backgroundColor,
		boxShadow: '0px 4px 8px 0px #000000',
		justifyContent: 'center',
	};
};

const acceptButton = ({ fontColor, backgroundColor }) => {
	return {
		backgroundColor: backgroundColor,
		color: fontColor,
		fontSize: 'inherit',
	};
};

const declineButton = ({ backgroundColor }) => {
	return {
		backgroundColor: 'transparent',
		color: backgroundColor,
		textDecoration: 'underline',
		fontSize: 'inherit',
	};
};

export { banner, acceptButton, declineButton };
