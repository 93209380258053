function getUID(uidCounterParameter) {
	if (uidCounterParameter && uidCounterParameter.indexOf('x') > -1)
		return uidCounterParameter.split('x')[0];

	return null;
}

function getCounter(uidCounterParameter) {
	if (uidCounterParameter && uidCounterParameter.split('x').length > 1) {
		const hexCounter = uidCounterParameter.split('x')[1];
		if (hexCounter.length > 0) {
			const counter = parseInt('0x' + hexCounter);
			if (!isNaN(counter)) return counter;
		}
	}

	return null;
}

export default { getUID, getCounter };
