import {
	mainDiv,
	group,
	label,
	content as baseContent,
} from '../product-info.styles';

const secondaryColor = '#CBB190';

const content = {
	...baseContent,
	letterSpacing: 0,
	lineHeight: '1.8rem',
	fontSize: 18,
	fontFamily: 'Mulish',
	fontWeight: 700,
};

const fieldRow = {
	display: 'flex',
};

const filterRow = {
	marginLeft: 35,
	marginRight: 35,
	marginBottom: 30,
};

const fieldLabel = {
	textAlign: 'left',
	flex: '0 0 50%',
};

const fieldValue = {
	textAlign: 'right',
	flex: '0 0 50%',
};

const select = {
	borderWidth: '0px 10px',
	borderColor: '#fff',
	backgroundColor: '#fff',
	height: 48,
	fontSize: 17,
};

const andorraLabel = {
	fontWeight: 400,
	marginBottom: 10,
};

const andorraValue = {
	fontSize: 50,
	color: secondaryColor,
	fontWeight: 900,
};

const roundImageCigar = {
	height: 64,
	width: 64,
	borderRadius: 64,
	border: '8px solid #2E2927',
	boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
	backgroundColor: 'white',
	objectFit: 'none',
	position: 'relative',
	top: -42,
};

const roundImageFlag = {
	height: 40,
	width: 40,
	borderRadius: 40,
	border: '2px solid white',
	backgroundColor: 'white',
	objectFit: 'none',
	margin: '15px 0px 25px 0px',
	boxShadow: '0px 4px 8px 0px #000000',
};

const savingButton = {
	backgroundColor: secondaryColor,
	fontSize: 20,
	lineHeight: '24px',
	width: '75%',
	maxWidth: 300,
	borderRadius: 30,
	height: 47,
	fontFamily: 'inherit',
	fontWeight: 800,
	border: 'none',
};

export {
	mainDiv,
	group,
	label,
	content,
	fieldRow,
	filterRow,
	fieldLabel,
	fieldValue,
	secondaryColor,
	roundImageFlag,
	roundImageCigar,
	andorraLabel,
	andorraValue,
	savingButton,
	select,
};
