const demoModeLabel = {
	position: 'fixed',
	bottom: 0,
	pointerEvents: 'none',
	zIndex: '99999',
	backgroundColor: '#b0141487',
	color: '#ffffffcf',
	fontWeight: 'bold',
	padding: '0px 5px',
	right: 0,
};

export { demoModeLabel };
