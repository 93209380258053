import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import {
	modal,
	modalHeader,
	cookieInformationContainer,
	paragraph,
	table,
	row,
	button,
	closeButtonContainer,
} from './cookie-policy.styles';

const CookiePolicy = (props) => {
	const { fontColor, backgroundColor, isModalOpen, onCloseModal } = props;
	const { t } = useTranslation(NAME_SPACE.COOKIE_POLICY);

	return (
		<>
			{isModalOpen && (
				<div id='policyModal' style={modal({ fontColor, backgroundColor })}>
					<h3 style={modalHeader}>{t('title')}</h3>
					<div style={cookieInformationContainer}>
						<p style={paragraph}>{t('description')}</p>

						<table style={table}>
							<thead>
								<tr>
									<th>{t('headers.cookieName')}</th>
									<th>{t('headers.defaultExpirationTime')}</th>
									<th>{t('headers.description')}</th>
								</tr>
							</thead>
							<tbody>
								<tr style={row}>
									<td>_ga</td>
									<td>{`2 ${t('time.years')}`}</td>
									<td>{t('cookies.ga.description')}</td>
								</tr>
								<tr style={row}>
									<td>_gid</td>
									<td>{`24 ${t('time.hours')}`}</td>
									<td>{t('cookies.gid.description')}</td>
								</tr>
								<tr style={row}>
									<td>_gat</td>
									<td>{`24 ${t('time.hours')}`}</td>
									<td>{t('cookies.gat.description')}</td>
								</tr>
								<tr style={row}>
									<td>CookieConsent</td>
									<td>{`1 ${t('time.year')}`}</td>
									<td>{t('cookies.cookieConsent.description')}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div style={closeButtonContainer}>
						<Button style={button({ fontColor })} onClick={onCloseModal}>
							{t('actions.close')}
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

export default CookiePolicy;
