import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import CookiePolicy from './cookie-policy';

const CookiePolicyLink = ({
	modalStyles = { fontColor: 'inherit', backgroundColor: 'transparent' },
	linkStyles = {
		fontColor: 'inherit',
		backgroundColor: 'transparent',
		fontSize: 'small',
		letterSpacing: 2,
	},
	linkText,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { t } = useTranslation(NAME_SPACE.COOKIE_POLICY);

	return (
		<>
			<CookiePolicy
				isModalOpen={isModalOpen}
				fontColor={modalStyles.fontColor}
				backgroundColor={modalStyles.backgroundColor}
				onCloseModal={() => setIsModalOpen(false)}
			/>
			<span style={linkStyles} onClick={() => setIsModalOpen(true)}>
				{linkText || t('title')}
			</span>
		</>
	);
};

export default CookiePolicyLink;
