import React from 'react';
import ResultRatingItem from './result-rating-item';
import {
	card,
	cardTitle,
	title,
	description,
	productImage,
	cardBody,
} from './result-rating-article.styles';
import ProductImageLink from '../../../product-image-link/product-image-link';

const ResultRatingArticle = ({
	article,
	articleResults,
	ratingIcons,
	styles,
}) => {
	return (
		<div style={card(styles?.fontColor)}>
			<div style={{ ...cardTitle }}>
				<span style={title(styles?.subtitleFontColor, styles?.fontColor)}>
					{article.name}
				</span>
			</div>
			{article.imageTag && article.imageUrl && (
				<div>
					<ProductImageLink
						title={article.name}
						imageUrl={article.imageUrl}
						style={{ ...productImage }}
					/>
				</div>
			)}
			<div style={{ ...cardBody }}>
				{article?.description && (
					<div>
						<p style={{ ...description }}>{article.description}</p>
					</div>
				)}
				{article &&
					article.ratingItems &&
					articleResults &&
					article.ratingItems.map((ratingItem, index) => {
						const ratingResults = articleResults.filter(
							(r) => r.ratingItemReference === ratingItem.reference
						);
						return (
							ratingItem &&
							ratingResults?.length > 0 && (
								<ResultRatingItem
									key={index}
									rating={ratingItem}
									results={ratingResults}
									ratingIcons={ratingIcons}
								/>
							)
						);
					})}
			</div>
		</div>
	);
};

export default ResultRatingArticle;
