import React, { useEffect } from 'react';

const Redirect = (props) => {
	const { redirectUrl } = props;

	useEffect(() => {
		window.location.href = redirectUrl;
	}, [redirectUrl]);

	return <></>;
};

export default Redirect;
