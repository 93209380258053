import React from 'react';
import ProductField from './product-field';

const ProductFields = (props) => {
	const { fields, fontColor } = props;

	return (
		<React.Fragment>
			{fields
				? fields.map((field, index) => {
						return (
							<ProductField key={index} fontColor={fontColor} field={field} />
						);
				  })
				: ''}
		</React.Fragment>
	);
};

export default ProductFields;
