import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/index.css';
import settings from 'services/settings';
import App from './App';
import './i18n';

settings
	.getFromHost()
	.then((s) =>
		ReactDOM.render(<App settings={s} />, document.getElementById('root'))
	);
