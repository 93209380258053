export const productJsonFieldNames = {
	CODIGO: 'CODIGO',
	MARCA: 'MARCA',
	ARTICULO: 'ARTICULO',
	ANDORRA_PVPM: 'ANDORRA_PVPM',
	ESPANA_PVP: 'ESPAÑA_PVP',
	ESPANA_SAVING: 'ESPAÑA_€',
	ESPANA_SAVING_PERCENT: 'ESPAÑA_%',
	FRANCIA_PVP: 'FRANCIA_PVP',
	FRANCIA_SAVING: 'FRANCIA_€',
	FRANCIA_SAVING_PERCENT: 'FRANCIA_%',
};
