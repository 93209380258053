import { useEffect, useState } from 'react';
import { useEventListener } from '../../../hooks';
import { ratingsApi } from '../../../services';
import { ratingCompletedResultTypes } from '../ratingTypes';

export function useProductRatingsForm(
	scanId,
	articles,
	ratingCompletedResultType
) {
	const [ratingsData, setRatingsData] = useState([]);
	const [ratingsResult, setRatingsResult] = useState();
	const [errors, setErrors] = useState();

	useEffect(() => {
		setRatingsData(
			articles
				.map((a) =>
					a.ratingItems.map((r) => ({
						ratingItemId: r.id,
						articleId: a.id,
						value: '',
					}))
				)
				.flat()
		);
	}, [articles]);

	function handleChange({ target }) {
		setRatingsData((prevData) =>
			prevData.map((p) => {
				return p.ratingItemId === target.name
					? { ...p, ['value']: target.value }
					: p;
			})
		);
	}

	useEventListener('change', handleChange);

	async function handleSubmit(event) {
		event.preventDefault();

		setErrors();
		try {
			const result = await ratingsApi.createRating({
				scanId: scanId,
				articleRatings: ratingsData,
			});

			if (result) {
				if (
					ratingCompletedResultType ===
					ratingCompletedResultTypes.AVERAGE_RATING
				) {
					const scores = await ratingsApi.getRatings(scanId);
					setRatingsResult({ ...scores });
				} else {
					setRatingsResult({});
				}
			}
		} catch (err) {
			setRatingsResult({
				scores: [],
			});
			setErrors(err?.message || 'Api error');
		}
	}

	useEventListener('submit', handleSubmit);

	return {
		ratingsData,
		handleSubmit,
		ratingsResult,
		errors,
	};
}
