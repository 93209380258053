import { experienceMapperHelper } from './experienceHelper';

function fillReplaceableFields({
	authenticationResult,
	authenticationData,
	usesTagTamper,
	tamperStatuses,
	serialNumber,
}) {
	var fields = {};
	if (authenticationResult) {
		fields['auth-result-text'] = authenticationData?.successful?.text || '';
		fields['auth-result-icon'] = authenticationData?.successful?.tag;
		fields['tamper-status-text'] =
			(usesTagTamper && tamperStatuses.filter((ts) => ts.active)[0]?.text) ||
			'';
		fields['tamper-status-icon'] =
			usesTagTamper && tamperStatuses.filter((ts) => ts.active)[0]?.tag;
		fields['serial-number'] = serialNumber;
	} else {
		fields['auth-result-text'] = authenticationData?.unsuccessful?.text || '';
		fields['auth-result-icon'] = authenticationData?.unsuccessful?.tag;
		fields['tamper-status-text'] = null;
		fields['tamper-status-icon'] = null;
		fields['serial-number'] = null;
	}

	return fields;
}

function replaceFields(htmlData, fields, mediaFiles) {
	var parser = new DOMParser();
	var doc = parser.parseFromString(htmlData, 'text/html');

	for (const [key, value] of Object.entries(fields)) {
		let replaceableNodes = doc?.querySelectorAll(`[data-replace=${key}]`);
		if (replaceableNodes && replaceableNodes.length > 0) {
			replaceableNodes.forEach((element) => {
				if (!value) {
					element.style.display = 'none';
				} else {
					if (element.tagName === 'IMG') {
						if (value) {
							element.src = `${experienceMapperHelper.getImageUrl(
								value,
								mediaFiles,
								null
							)}#TOKEN#`;
						}
					} else {
						element.innerHTML = value;
					}
				}
			});
		}
	}

	return doc.body.innerHTML;
}

export const replacePageContentHelper = {
	fillReplaceableFields,
	replaceFields,
};
