import { height, color } from 'assets/common.styles';

const rows = {
	display: 'flex',
};

const rightPanel = {
	textAlign: 'left',
	paddingTop: 10,
	fontSize: height > 640 ? 14 : 12,
	color: color,
	letterSpacing: 2,
	marginLeft: -20,
};

const group = {
	marginBottom: height > 640 ? 20 : 15,
};

const label = {
	fontWeight: 'bold',
};

export { rows, rightPanel, group, label };
