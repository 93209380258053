import { width, height, color } from 'assets/common.styles';

const mainDiv = (height, background, backgroundColor, fontColor) => {
	return {
		width: width,
		height: height,
		backgroundImage: background ? 'url(' + background + ')' : 'none',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		overflowY: 'scroll',
		backgroundColor: backgroundColor,
		color: fontColor,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	};
};

const rows = {
	display: 'flex',
	justifyContent: 'center',
};

const group = {
	marginBottom: height > 640 ? 20 : 15,
};

const label = {
	fontWeight: 'bold',
};

const content = {
	textAlign: 'center',
	fontSize: height > 640 ? 14 : 12,
	color: color,
	letterSpacing: 2,
};

const fieldsTitle = {
	fontWeight: 900,
	fontSize: 18,
	marginLeft: 35,
	marginTop: 10,
};

const divider = {
	marginLeft: 35,
	marginRight: 35,
	borderColor: color,
};

const productImage = {
	objectFit: 'contain',
	width: '80%',
	marginBottom: 10,
};

export {
	mainDiv,
	group,
	label,
	content,
	fieldsTitle,
	divider,
	productImage,
	rows,
};
