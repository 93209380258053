import React from 'react';
import { secondaryColor } from '../product-info.styles';

const ProductInfoHeaderBackButton = (props) => {
	return (
		<div>
			<svg height={46} width={46} onClick={props.onClick}>
				<circle id='what' cx='23' cy='23' r='23' fill={secondaryColor} />
				<polyline
					points='25,17 19,23 25,29'
					fill='none'
					stroke={props.backgroundColor}
					strokeWidth='2.5'
				/>
				<span style={{ fontColor: props.fontColor }} onClick={props.onClick}>
					&lt;
				</span>
			</svg>
		</div>
	);
};

export default ProductInfoHeaderBackButton;
