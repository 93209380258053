import React from 'react';
import { Container } from 'reactstrap';
import { mainDiv, content } from '../product-info.styles';
import ProductInfoHeader from '../product-info-header/product-info-header';
import CookiePolicyLink from '../../cookie-policy/cookie-policy-link';

const ProductImageView = (props) => {
	const {
		productName,
		setViewByName,
		imageUrl,
		fontColor,
		backgroundImageUrl,
		backgroundColor,
		productInfoViewReference,
		showTitle,
	} = props;

	return (
		<div
			id='maindiv'
			style={mainDiv('100vh', backgroundImageUrl, backgroundColor, fontColor)}
		>
			<Container className='row' style={content}>
				<ProductInfoHeader
					productName={productName}
					showTitle={showTitle}
					setViewByName={setViewByName}
					showBackButton={true}
					fontColor={fontColor}
					previousViewReference={productInfoViewReference}
				/>
				<img src={imageUrl} style={{ width: '100vw' }} alt='Extended info' />
			</Container>
			<CookiePolicyLink
				modalStyles={{
					fontColor: props.fontColor,
					backgroundColor: props.backgroundColor,
				}}
			/>
		</div>
	);
};

export default ProductImageView;
