const titleRow = {
	display: 'flex',
	justifyContent: 'center',
	paddingTop: 20,
	paddingBottom: 20,
};

const headerColumnCommon = {
	alignSelf: 'center',
};

const headerBackButtonColumn = {
	...headerColumnCommon,
	flex: 2,
	fontSize: 30,
};

const headerProductNameColumn = {
	flex: 6,
	letterSpacing: 2,
};

const headerIconColumn = {
	...headerColumnCommon,
	flex: 4,
	display: 'flex',
	justifyContent: 'space-evenly',
};

export {
	titleRow,
	headerBackButtonColumn,
	headerProductNameColumn,
	headerIconColumn,
};
