const ratingTypes = {
	SCORE: 0,
	SELECTOR: 1,
};

const ratingCompletedResultTypes = {
	GRATITUDE_MESSAGE: 0,
	AVERAGE_RATING: 1,
};

export { ratingTypes, ratingCompletedResultTypes };
