const titleContainer = {
	marginBottom: 5,
	display: 'flex',
};

const title = {
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
};

const optionContainer = {
	display: 'flex',
	flexWrap: 'wrap',
};

const optionChip = {
	padding: '7px 10px',
	margin: '2px 2px 0px 0px',
	borderWidth: 1,
	borderStyle: 'solid',
	borderRadius: 15,
	fontSize: '0.9rem',
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	overflow: 'hidden',
};

const optionLabel = {
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
};

export { titleContainer, title, optionContainer, optionChip, optionLabel };
