import React, { useMemo } from 'react';

export const TamperResult = ({ fontColor, fontFamily, tamperStatuses }) => {
	const tamperStatus = useMemo(() => {
		return tamperStatuses?.filter((status) => status.active)[0];
	}, [tamperStatuses]);

	return (
		<div style={{ marginTop: 20 }}>
			{tamperStatus && tamperStatus.imageUrl && (
				<img width='80' height='80' src={tamperStatus.imageUrl} alt='tamper' />
			)}
			<p
				style={{
					color: fontColor,
					fontFamily: fontFamily,
					fontSize: 16,
				}}
			>
				{tamperStatus && (tamperStatus.text ?? '')}
			</p>
		</div>
	);
};
