function parseBeforeExecute(number, cb) {
	if (typeof number === 'number') {
		return cb(number);
	} else if (number && number.trim().length > 0) {
		const parsedNumber = Number(number);
		if (!isNaN(parsedNumber)) {
			return cb(parsedNumber);
		}
	}
	return '';
}

function asCurrencyString(number, localeCode, currency = 'EUR') {
	return parseBeforeExecute(number, (parsedNumber) =>
		new Intl.NumberFormat(localeCode, {
			currency: currency,
			style: 'currency',
		}).format(parsedNumber)
	);
}

function asPercentString(
	number,
	localeCode,
	minimumFractionDigits = 1,
	maximumFractionDigits = 1
) {
	return parseBeforeExecute(number, (parsedNumber) =>
		new Intl.NumberFormat(localeCode, {
			style: 'percent',
			minimumFractionDigits: minimumFractionDigits,
			maximumFractionDigits: maximumFractionDigits,
		}).format(parsedNumber)
	);
}

export default {
	asCurrencyString,
	asPercentString,
};
