import { rows } from '../product-info.styles';

const titleRow = {
	...rows,
	paddingTop: 20,
};

const puroImage = {
	objectFit: 'contain',
	width: '100%',
	marginTop: 30,
	marginBottom: 30,
};

export { titleRow, puroImage };
