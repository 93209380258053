import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { googleAnalytics } from 'services';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import CookiePolicyLink from '../cookie-policy/cookie-policy-link';
import {
	banner,
	acceptButton,
	declineButton,
} from './cookie-policy-consent.styles';

const CookiePolicyConsent = (props) => {
	const { t } = useTranslation(NAME_SPACE.COMMON);
	const { backgroundColor, fontColor, onRejectCookies } = props;

	const handleAcceptCookie = () => {
		googleAnalytics.init();
	};

	const handleDeclineCookie = () => {
		//remove google analytics cookies
		Cookies.remove('_ga');
		const analyticsKey = (
			localStorage.getItem('GOOGLE_ANALYTICS_KEY') || ''
		).replaceAll('-', '_');
		Cookies.remove(`_gat_gtag_${analyticsKey}`);
		Cookies.remove('_gid');

		if (onRejectCookies) {
			onRejectCookies();
		}

		window.close();
	};

	return (
		<CookieConsent
			overlay
			enableDeclineButton={false}
			setDeclineCookie={false}
			buttonText={t('actions.accept')}
			declineButtonText={t('actions.decline')}
			onAccept={handleAcceptCookie}
			onDecline={handleDeclineCookie}
			style={banner({ fontColor, backgroundColor })}
			buttonStyle={acceptButton({ fontColor, backgroundColor })}
			declineButtonStyle={declineButton({ backgroundColor })}
		>
			<p>
				{t(`${NAME_SPACE.COOKIE_POLICY}:consent`)}&nbsp;
				<CookiePolicyLink
					linkText={t('actions.learnMore')}
					fontSize='inherit'
					modalStyles={{
						fontColor: props.fontColor,
						backgroundColor: props.backgroundColor,
					}}
					linkStyles={{
						fontSize: 'unset',
						textDecoration: 'underline',
					}}
				/>
			</p>
		</CookieConsent>
	);
};

export default CookiePolicyConsent;
