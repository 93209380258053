import React from 'react';
import { ratingCompletedResultTypes } from '../../ratingTypes';
import ResultRatingArticle from './rating-article/result-rating-article';
import { RatingResultMessage } from './rating-result-message';

export const RatingResults = ({
	articles,
	ratingResults,
	ratingIcons,
	ratingErrors,
	ratingCompletedResultType,
	ratingGratutideMessage,
	styles,
}) => {
	return (
		<>
			{ratingErrors ? (
				<span>{ratingErrors || 'Api error'}</span>
			) : (
				<>
					{ratingCompletedResultType ===
					ratingCompletedResultTypes.GRATITUDE_MESSAGE ? (
						<RatingResultMessage message={ratingGratutideMessage} />
					) : (
						(articles || []).map((article, index) => (
							<ResultRatingArticle
								key={index}
								article={article}
								articleResults={ratingResults?.scores?.filter(
									(r) => r.articleName === article.name
								)}
								ratingIcons={ratingIcons}
								styles={{ ...styles }}
							/>
						))
					)}
				</>
			)}
		</>
	);
};
