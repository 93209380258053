import React from 'react';
import { IconRating } from 'components/icon-rating/icon-rating';
import { ratingTypes } from '../../../ratingTypes';
import {
	titleContainer,
	title,
	optionContainer,
	optionChip,
	optionLabel,
} from './result-rating-item.styles';

const ResultRatingItem = ({ rating, results = [], ratingIcons }) => {
	const ratingTotals = results
		.filter((r) => r.optionReference)
		.reduce((accumulator, r) => accumulator + r.score, 0);

	return (
		<div style={{ marginTop: 10 }}>
			{rating.type === ratingTypes.SCORE ? (
				<IconRating
					label={rating.name}
					value={results[0]?.score || ''}
					{...ratingIcons}
				/>
			) : (
				<>
					<div style={titleContainer}>
						<span style={title}>{rating.name}</span>
					</div>
					<div style={optionContainer}>
						{results.map((result, index) => {
							return (
								<div style={optionChip} key={index}>
									<span style={optionLabel}>
										{rating.options.filter(
											(o) =>
												o.reference === result.optionReference &&
												rating.reference === result.ratingItemReference
										)[0]?.name || result.optionReference}
									</span>
									<span>:&nbsp;</span>
									<span>
										{`${Math.floor(
											(ratingTotals
												? (100 * (result.score ?? 0)) / ratingTotals
												: 0
											).toFixed(1)
										).toLocaleString()}%`}
									</span>
								</div>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
};

export default ResultRatingItem;
